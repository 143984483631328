import { Constructable } from '../declaration/type/constructable';

const map: Record<string, any> = {};

/**
 * Service singleton
 */
export function service<T>(M: Constructable, name: string): T {
  if (!map[name]) {
    map[name] = new M();
  }

  return map[name] as any;
}
