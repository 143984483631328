import { N_theme } from '@/declaration/type/n_theme';
import { Theme } from '@/service/$theme';
import { createContext } from 'react';

export interface T_context_theme {
  current: N_theme | null;
  service: Theme;
}

export const Context_theme = createContext<T_context_theme>({} as T_context_theme);
