import { T_user_setting } from '../declaration/rds/model/type/t_user_setting';
import { T_signer } from '../declaration/type/t_signer';
import { $api } from '../service/$api';
import { get_tokens } from './accounts';
import { radio } from './radio';

export function logged_in(): boolean {
  return !!get_tokens();
}

export function logout(): void {
  radio.authorization.next(null);
}

export async function load_signer(authorization?: string): Promise<T_signer | undefined> {
  if (get_tokens() || authorization) {
    try {
      let opt;
      if (authorization) {
        opt = { headers: { authorization: $api.build_header_authorization(authorization) } };
      }
      return $api.call('account/get_signer', undefined, opt).catch();
    } catch (e: any) {
      if (e.eid === 'e8') {
        logout();
      }
    }
  }
}

export async function user_setting_set(path: string, value: any): Promise<T_user_setting | undefined> {
  if (get_tokens()) {
    return $api.call('user/setting_set', { path, value }).catch();
  }
}
