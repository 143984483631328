import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import merge from 'lodash/merge';
import { opt_common_theme } from './common';

export const theme_light = createTheme(
  merge<ThemeOptions, ThemeOptions>(opt_common_theme, {
    palette: {
      mode: 'light',
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // borderColor: 'black',
          },
        },
      },
    },
  } as ThemeOptions),
);
