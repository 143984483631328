import { cookie_parse } from '@/utility/http/cookie_parse';
import merge from 'lodash/merge';
import { GetServerSidePropsContext } from 'next';
import { N_auth_node_mode } from '../declaration/rds/model/type/n_auth_node_mode';
import { N_lkey } from '../declaration/type/n_lkey';
import { T_env_auth_replica } from '../declaration/type/t_env_auth_replica';
import { env } from '../env/env';
import { get_tokens } from '../state/accounts';
import { radio } from '../state/radio';
import { is_browser } from '../utility/browser/is_browser';
import { lang_to_dash } from '../utility/i18n/lang_to_dash';
import lget from '../utility/storage/lget';
import { service } from './service';

const name = 'api';
export type T_header = Record<string, string>;
export type T_api_opt = RequestInit & { base?: string; headers?: T_header; ctx_next?: GetServerSidePropsContext };

export class Api {
  async send<T = any>(method: string, seg: string = '', body?: any, opt?: T_api_opt): Promise<T> {
    const base = opt?.base ?? env.base;
    const url = base + seg;
    const json_type = 'application/json';

    let headers: any;
    if (is_browser()) {
      headers = this.build_headers_browser(opt?.headers);
    } else {
      if (opt?.ctx_next) {
        this.build_headers_next(opt.ctx_next);
      }
    }

    const o = merge<RequestInit, RequestInit>(
      {
        method,
        headers: {
          ...headers,
          accept: 'application/json, text/plain, */*',
          'content-type': json_type,
        },
      },
      opt || {},
    ) as RequestInit;

    if (body === '' || body === undefined) {
      delete (o.headers as any)?.['content-type'];
    } else {
      if ((o.headers as any)?.['content-type']?.startsWith(json_type)) {
        body = JSON.stringify(body);
      }
      o.body = body;
    }

    try {
      const r = await fetch(url, o);
      const json = await r.json();
      if (r.ok) {
        return json?.data;
      } else {
        const error = new Error();
        for (const key in json.error) {
          // @ts-ignore
          error[key] = json.error[key];
        }
        throw error;
      }
    } catch (e: any) {
      // if (e.eid) {
      radio.error.next(e);
      // }
      throw e;
    }
  }

  call_auth<T = any>(seg: string, body?: any, opt?: T_api_opt): Promise<T> {
    const { node_mode, base } = (env.auth || {}) as T_env_auth_replica;
    opt = opt || {};
    if (node_mode === N_auth_node_mode.replica) {
      opt.base = base;
    }

    return this.call(seg, body, opt);
  }

  call<T = any>(seg: string, body?: any, opt?: T_api_opt): Promise<T> {
    return this.send<T>('post', 'call/' + seg, body, opt);
  }

  call_ssr<T = any>(seg: string, body?: any, opt?: T_api_opt, ctx?: GetServerSidePropsContext): Promise<T> {
    const { req } = ctx || {};
    const headers: any = {};
    const token = cookie_parse(req as any).authorization;
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return $api.call(seg, body, { headers });
  }

  build_headers_browser(patch?: T_header): Record<string, string> {
    const lang = lget([N_lkey.setting, 'language']);
    const r = {} as any;
    if (lang) {
      r['accept-language'] = lang_to_dash(lang);
    }

    const token = get_tokens()[0]?.authorization;
    if (token) {
      r.authorization = this.build_header_authorization(token);
    }

    return { ...r, ...patch };
  }

  build_headers_next(ctx: GetServerSidePropsContext, patch?: T_header): Record<string, string> {
    const r = {} as any;
    const lang = ctx.locale;
    if (lang) {
      r['accept-language'] = lang_to_dash(lang);
    }
    return { ...r, ...patch };
  }

  build_header_authorization(value: string): string {
    return `Bearer ${value}`;
  }
}

export const $api = service<Api>(Api, name);
