import { N_auth_node_mode } from '../declaration/rds/model/type/n_auth_node_mode';
import { N_passport_provider } from '../declaration/type/n_passport_provider';
import { T_env } from '../declaration/type/t_env';

export const env: T_env = {
  host: 'sandbox.wozuguo.com',
  production: true,
  base: 'https://1.api.sandbox.wozuguo.com/',
  brand: '我租过',
  mix_mode: false,
  ws: {
    main: {
      url: 'wss://1.api.sandbox.wozuguo.com/ws/main',
    },
  },
  antibot: {
    vcode_cd: 60,
  },
  auth: {
    node_mode: N_auth_node_mode.master, // replica use master to manage user account
  },
  currency: {
    main: {
      name: 'CNY',
      symbol: '¥',
    },
  },
  cdn: {
    public: {
      base: 'https://wozuguo-sandbox.oss-cn-hangzhou.aliyuncs.com/',
    },
  },
  passport: {
    provider: {
      [N_passport_provider.wechat]: {
        enabled: true,
        key: 'wx9b2abe0c10bfb97f',
      },
    },
  },
  geo: {
    api_key_amap: '832b47fdd60c5fb5436def258c0a933d',
  },
  // soon: {
  //   subscription: {
  //     vcode_mode: N_type_vcode.image,
  //   },
  // },
};
