import difference from 'lodash/difference';
import { createContext } from 'react';
import { Subject } from 'rxjs';
import { N_event_ws } from '../declaration/type/n_event_ws';
import { N_event_ws_sub } from '../declaration/type/n_event_ws_sub';
import { env } from '../env/env';
import { get_tokens } from '../state/accounts';

export type T_opt_ws = {
  /**
   * (ms)
   */
  timeout?: number;

  /**
   * Retry internal, 0 means don't retry (ms)
   */
  retry?: number;
  max_retry?: number;

  /**
   * Force reconnect interval, 0 means don't reconnect (ms)
   */
  lifetime?: number;
};

export class Ws {
  radio = new Subject<any>();
  cycling = false;
  protected socket!: WebSocket;
  protected opt: T_opt_ws;
  protected retry_count = 0;
  protected subs: Set<N_event_ws> = new Set();
  protected args: Partial<Record<N_event_ws, any>> = {};
  protected timer_retry?: any;

  constructor(
    protected url: string,
    opt?: T_opt_ws,
  ) {
    this.opt = { timeout: 3 * 1000, retry: 2 * 1000, lifetime: 20 * 1000, max_retry: 24, ...opt };
  }

  /**
   * ensure socket is connected
   */
  async ensure(): Promise<void> {
    if (!this.socket || this.is_closed()) {
      await this.reconnect();
    }
  }

  is_closed(): boolean {
    return this.socket?.readyState === WebSocket.CLOSED;
  }

  is_pending(): boolean {
    return [WebSocket.CONNECTING, WebSocket.CLOSING].includes(this.socket?.readyState as any);
  }

  start_cycle(): void {
    this.cycling = true;
    setInterval(() => {
      if (this.is_pending()) {
        return;
      }
      void this.reconnect();
      // this.socket.close();
    }, this.opt.lifetime);
  }

  async reconnect(): Promise<Event> {
    let {
      socket,
      // opt: { max_retry, retry },
    } = this;

    if (socket) {
      socket.close();
    }

    socket = this.socket = new WebSocket(this.url);

    // clearTimeout(this.timer_retry);
    // this.timer_retry = setTimeout(() => {
    //   if (this.is_closed()) {
    //     console.info('Socket connection closed, readyState:', socket.readyState);
    //     socket.close();
    //   }
    // }, this.opt.timeout);

    return new Promise((resolve, reject) => {
      socket.addEventListener('open', (event: Event) => {
        if (!this.cycling) {
          this.start_cycle();
        }

        if (this.subs.size) {
          const events = Array.from(this.subs);
          this.subs.clear();
          this.subscribe({ events, args: this.args });
        }
        resolve(event);
      });

      socket.addEventListener('message', (event: MessageEvent<string>) => {
        this.radio.next(JSON.parse(event.data));
      });

      socket.addEventListener('error', (e) => {
        // if (retry && this.retry_count < (max_retry || 24)) {
        //   setTimeout(() => {
        //     this.retry_count++;
        //     this.reconnect();
        //   }, retry * this.retry_count);
        // }
        reject(e);
      });
    });
  }

  async subscribe(
    { events, args }: T_ws_input,
    type: N_event_ws_sub.subscribe | N_event_ws_sub.unsubscribe = N_event_ws_sub.subscribe,
  ): Promise<O_subscribe_ws> {
    await this.ensure();

    const subs_arr = Array.from(this.subs);
    let es = events;
    if (type === N_event_ws_sub.subscribe) {
      es = difference(es, subs_arr);
      events?.forEach((it) => this.subs.add(it));
      this.args = { ...this.args, ...args };
    } else {
      events?.forEach((it) => {
        this.subs.delete(it);
        delete this.args[it];
      });
    }

    if (es.length && !this.is_pending()) {
      this.socket.send(
        JSON.stringify({
          type,
          data: { events: es, args, authorization: get_tokens()[0]?.authorization },
        }),
      );
    }

    const { socket } = this;
    return new Promise((resolve, reject) => {
      socket.addEventListener('message', (event: MessageEvent<any>) => {
        resolve({ radio: this.radio, event, data: JSON.parse(event.data) });
      });

      socket.addEventListener('error', reject);
    });
  }

  async unsubscribe(opt: T_ws_input): Promise<void> {
    await this.subscribe(opt, N_event_ws_sub.unsubscribe);
  }

  send(type: N_event_ws, data?: any): void {
    this.socket.send(JSON.stringify({ type, data }));
  }
}

export type T_context_ws = {
  main?: Ws;
};

export const Context_ws = createContext<T_context_ws>({} as T_context_ws);

export function context_ws_value_create(): T_context_ws {
  if (!env.ws?.main) {
    console.info('ws is not enabled, empty: `env.ws.main`');
    return {} as T_context_ws;
  }
  return {
    main: new Ws(env.ws.main.url),
  };
}

export interface O_subscribe_ws {
  radio: Subject<any>;
  event: MessageEvent<any>;
  /**
   * initial data
   */
  data: Record<string, any>;
}

export interface T_ws_input extends Record<string, any> {
  events: N_event_ws[];
  args?: Partial<Record<N_event_ws, any>>;
}
