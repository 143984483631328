import Btn_action from '@/component/button/btn_action/btn_action';
import { Context_auth } from '@/context/context_auth';
import { Context_nf } from '@/context/context_nf';
import { Context_theme } from '@/context/context_theme';
import { Context_ws } from '@/context/context_ws';
import { useInit } from '@/hook/app/useInit';
import { loading } from '@/utility/dynamic/loading';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import Next_nprogress from 'nextjs-progressbar';
import { SnackbarProvider } from 'notistack';
import React, { FC, HTMLAttributes, memo } from 'react';

export interface I_Root extends HTMLAttributes<HTMLDivElement> {}

const Modal_control = dynamic(() => import('@/component/modal_control/modal_control'), { loading });
const Popup_control = dynamic(() => import('@/component/popup_control/popup_control'), { loading });

// Create a client
const query_client = new QueryClient();

const Root: FC<I_Root> = memo<I_Root>((props: I_Root) => {
  const { children } = props;
  const {
    root: {
      notistack: { ref: notistack_ref, dismiss: notistack_dismiss },
      theme,
      context: { ws: context_value_ws, theme: context_value_theme },
    },
    auth: {
      context: { auth: context_value_auth },
    },
    nf: {
      context: { nf: context_value_nf },
    },
  } = useInit();

  return (
    <QueryClientProvider client={query_client}>
      <Context_ws.Provider value={context_value_ws}>
        <Context_auth.Provider value={context_value_auth}>
          <Context_theme.Provider value={context_value_theme}>
            <ThemeProvider theme={theme}>
              <Context_nf.Provider value={context_value_nf}>
                <SnackbarProvider
                  ref={notistack_ref}
                  action={(key) => <Btn_action onClick={notistack_dismiss(key)}>👌</Btn_action>}
                >
                  <Next_nprogress color="rgba(150, 150, 150, .7)" />
                  <Popup_control />
                  <Modal_control />
                  {children}
                </SnackbarProvider>
              </Context_nf.Provider>
            </ThemeProvider>
          </Context_theme.Provider>
        </Context_auth.Provider>
      </Context_ws.Provider>
    </QueryClientProvider>
  );
});

export default Root;
