/**
 * From Query string
 */
export default function from_qs<T = Record<string, string>>(query: string): T {
  const raw = new URLSearchParams(query);
  const result = {};
  for (const [key, value] of raw.entries() as any) {
    // each 'entry' is a [key, value] tupple
    // @ts-ignore
    result[key] = value;
  }

  return result as T;
}
