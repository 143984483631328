import { PaletteColorOptions } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles/createTheme';

const color = require('@/style/overwrite/color');

declare module '@mui/material/styles' {
  interface PaletteOptions {
    buy?: PaletteColorOptions;
    sell?: PaletteColorOptions;
    white?: PaletteColorOptions;
    black?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buy: true;
    sell: true;
    white: true;
    black: true;
  }
}

export const opt_common_theme: ThemeOptions = {
  palette: {
    background: {
      default: 'hsla(var(--color-background) / var(--tw-bg-opacity))',
      paper: 'hsla(var(--color-background) / var(--tw-bg-opacity))',
    },
    white: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
      contrastText: '#222',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      contrastText: '#eee',
    },
    buy: {
      main: color.buy,
    },
    sell: {
      main: color.sell,
    },
  },
  shape: {
    borderRadius: 10, // Set your desired global border-radius here
  },
};
