export enum N_event_ws {
  heartbeat = 'heartbeat',
  model__create = 'model__create',
  model__read = 'model__read',
  model__update = 'model__update',
  model__delete = 'model__delete',
  model__nf_create = 'model__nf_create',
  model__nf_read = 'model__nf_read',
  model__nf_update = 'model__nf_update',
  model__nf_delete = 'model__nf_delete',
  model__nf_count_unread = 'model__nf_count_unread',
  model__message_unread = 'model__message_unread',
  model__message_read = 'model__message_read',
  model__message_update = 'model__message_update',
  model__message_delete = 'model__message_delete',
  model__message_count_unread = 'model__message_count_unread',
  model__message_count_unread_total = 'model__message_count_unread_total',
  model__message_brief_unread = 'model__message_brief_unread',
}
