import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import merge from 'lodash/merge';
import { opt_common_theme } from './common';

export const theme_dark = createTheme(
  merge(opt_common_theme, {
    palette: {
      mode: 'dark',
    },
  } as ThemeOptions),
);
