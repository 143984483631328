import { T_context_auth } from '@/context/context_auth';
import { O_check_unset_info } from '@/declaration/api/type/o_check_unset_info';
import { T_token_bag } from '@/declaration/api/type/t_token_bag';
import { N_role } from '@/declaration/rds/model/type/n_role';
import { N_lkey } from '@/declaration/type/n_lkey';
import { T_account_with_user } from '@/declaration/type/t_account_with_user';
import { T_signer } from '@/declaration/type/t_signer';
import { x_signer_set_password } from '@/declaration/type/x_signer_set_password';
import { useApi } from '@/hook/api/useApi';
import useQueryTo from '@/hook/router/useQueryTo';
import { $api } from '@/service/$api';
import { get_tokens, pin_token, remove_token } from '@/state/accounts';
import { load_signer, user_setting_set } from '@/state/auth';
import { radio } from '@/state/radio';
import { signer_is } from '@/utility/auth/signer_is';
import lget from '@/utility/storage/lget';
import lset from '@/utility/storage/lset';
import cloneDeep from 'lodash/cloneDeep';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCounter, usePrevious } from 'react-use';
import { Subscription } from 'rxjs';

export interface O_init_auth {
  context: {
    auth: T_context_auth;
  };
}

export function useInitAuth(): O_init_auth {
  const { subtract } = useQueryTo();
  const [signer, set_signer] = useState<T_signer | null>(null);
  const [signer_load_count, { inc: signer_load_count_inc }] = useCounter(0);
  const [auths, set_auths] = useState<T_token_bag[]>([]);
  const { pathname, push } = useRouter();
  const [reload_signer_fn, set_reload_signer_fn] = useState<any>();
  const query = useSearchParams();
  const query_prev = usePrevious(query);
  const { call: check_unset_info } = useApi('account/check_unset_info');
  const { patch } = useQueryTo();

  const setting_set = useCallback(async (path: string, value: any): Promise<void> => {
    if (get_tokens()) {
      const setting = await user_setting_set(path, value);
      if (setting) {
        set_signer((s) => {
          const copy = cloneDeep(s) as T_account_with_user;
          copy.r_user.setting = setting;
          return copy;
        });
      }
    }
  }, []);

  const context_value_auth = useMemo<T_context_auth>(
    () => ({
      signer,
      reload_signer: reload_signer_fn as any,
      setting_set,
      auths,
      load_count: signer_load_count,
      is_role(role: N_role) {
        return signer_is(signer, role);
      },
    }),
    [auths, signer_load_count, reload_signer_fn, setting_set, signer],
  );

  useEffect(() => {
    let dead = false;
    const sub: Record<string, Subscription> = {};
    // const l = $language;

    setup_auth();

    function setup_auth(): void {
      sub.authorization = radio.authorization.subscribe(async (v) => {
        if (v) {
          pin_token(v);
          await reload_signer();
          // radio.popup.next({
          //   title: `${t('auth:logged_in')}: ${v.signer?.unique}`,
          //   type: 'success',
          //   duration: 1000,
          // } as T_popup);
          const redirect = lget([N_lkey.sign_redirect]);
          if (redirect) {
            void push(redirect);
            lset([N_lkey.sign_redirect], null);
          } else {
            if (pathname.startsWith('/auth/') || pathname.startsWith('/passport/')) {
              void push('/');
            }
          }

          // clear inviter id after login
          lset([N_lkey.id_inviter], null);
        } else {
          set_signer(null);
          remove_token();
          const next = get_tokens()[0];
          if (next) {
            radio.authorization.next(next);
          } else {
            set_signer(null);
          }
        }
        set_auths(get_tokens());
      });

      set_reload_signer_fn(() => reload_signer);
      // subscribe to signer authorization change
      // user signer (current user) data
      set_auths(get_tokens());
      void reload_signer();
    }

    async function reload_signer(): Promise<void> {
      const authorization = get_tokens()[0]?.authorization;
      if (authorization) {
        const signer = await load_signer();
        if (dead) {
          return;
        }
        if (signer) {
          pin_token({ authorization, signer });
          document.body.dataset.signer = signer.id.toString();
          const user = signer.r_user;
          if (user) {
            user.r_account = signer;
          }
          set_signer(signer);
        } else {
          document.body.dataset.signer = undefined;
        }
      }
      signer_load_count_inc();
    }

    return () => {
      dead = true;
      for (const key in sub) {
        sub[key]?.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // login with token taker
    const secret = query.get('token_take');
    if (secret && secret !== query_prev?.get('token_take')) {
      $api
        .call('account/token_take', { secret })
        .then(({ authorization, signer }) => {
          pin_token({ authorization, signer });
          set_signer(signer as T_signer);
        })
        .finally(() => subtract(['token_take']));
    }

    // login with url (which contains token)
    const authorization = query.get('authorization');
    if (authorization && authorization !== query_prev?.get('authorization')) {
      $api
        .call('account/get_signer', undefined, {
          headers: { authorization: $api.build_header_authorization(authorization) },
        })
        .then(({ authorization, signer }) => {
          pin_token({ authorization, signer });
          set_signer(signer as T_signer);
        })
        .finally(() => subtract(['authorization']));
    }
  }, [query, query_prev, subtract]);

  useEffect(() => {
    if (signer?.id) {
      check_unset_info().then((v: O_check_unset_info) => {
        if (v.empty.includes('password')) {
          patch({ [x_signer_set_password]: '1' });
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!signer && query.get('id_inviter')) {
      // save inviter id to local storage if not logged in and has inviter id
      lset([N_lkey.id_inviter], query.get('id_inviter'));
    }
  }, [query, signer]);

  return {
    context: {
      auth: context_value_auth,
    },
  };
}
