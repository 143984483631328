const tw_color = require('tailwindcss/colors');
const color = {
  black_alpha_25: 'rgba(0, 0, 0, .025)',
  black_alpha_50: 'rgba(0, 0, 0, .05)',
  black_alpha_75: 'rgba(0, 0, 0, .075)',
  black_alpha_100: 'rgba(0, 0, 0, .1)',
  black_alpha_150: 'rgba(0, 0, 0, .15)',
  black_alpha_200: 'rgba(0, 0, 0, .2)',
  black_alpha_250: 'rgba(0, 0, 0, .25)',
  black_alpha_300: 'rgba(0, 0, 0, .3)',
  black_alpha_350: 'rgba(0, 0, 0, .35)',
  black_alpha_400: 'rgba(0, 0, 0, .4)',
  black_alpha_450: 'rgba(0, 0, 0, .45)',
  black_alpha_500: 'rgba(0, 0, 0, .5)',
  black_alpha_550: 'rgba(0, 0, 0, .55)',
  black_alpha_600: 'rgba(0, 0, 0, .6)',
  black_alpha_650: 'rgba(0, 0, 0, .65)',
  black_alpha_700: 'rgba(0, 0, 0, .7)',
  black_alpha_750: 'rgba(0, 0, 0, .75)',
  black_alpha_800: 'rgba(0, 0, 0, .8)',
  black_alpha_850: 'rgba(0, 0, 0, .85)',
  black_alpha_900: 'rgba(0, 0, 0, .9)',
  black_alpha_950: 'rgba(0, 0, 0, .95)',
  white_alpha_25: 'rgba(255, 255, 255, .025)',
  white_alpha_50: 'rgba(255, 255, 255, .05)',
  white_alpha_75: 'rgba(255, 255, 255, .075)',
  white_alpha_100: 'rgba(255, 255, 255, .1)',
  white_alpha_150: 'rgba(255, 255, 255, .15)',
  white_alpha_200: 'rgba(255, 255, 255, .2)',
  white_alpha_250: 'rgba(255, 255, 255, .25)',
  white_alpha_300: 'rgba(255, 255, 255, .3)',
  white_alpha_350: 'rgba(255, 255, 255, .35)',
  white_alpha_400: 'rgba(255, 255, 255, .4)',
  white_alpha_450: 'rgba(255, 255, 255, .45)',
  white_alpha_500: 'rgba(255, 255, 255, .5)',
  white_alpha_550: 'rgba(255, 255, 255, .55)',
  white_alpha_600: 'rgba(255, 255, 255, .6)',
  white_alpha_650: 'rgba(255, 255, 255, .65)',
  white_alpha_700: 'rgba(255, 255, 255, .7)',
  white_alpha_750: 'rgba(255, 255, 255, .75)',
  white_alpha_800: 'rgba(255, 255, 255, .8)',
  white_alpha_850: 'rgba(255, 255, 255, .85)',
  white_alpha_900: 'rgba(255, 255, 255, .9)',
  white_alpha_950: 'rgba(255, 255, 255, .95)',
  background: 'hsla(var(--color-background) / <alpha-value>)', // body background color
  background_vivid: 'hsla(var(--color-background-vivid) / <alpha-value>)', // body background color
  background_dull: 'hsla(var(--color-background-dull) / <alpha-value>)', // body background color
  foreground: 'hsla(var(--color-foreground) / <alpha-value>)', // body text color
  foreground_vivid: 'hsla(var(--color-foreground-vivid) / <alpha-value>)', // body text color
  foreground_dull: 'hsla(var(--color-foreground-dull) / <alpha-value>)', // body text color
  primary: 'hsla(var(--color-primary) / <alpha-value>)',
  primary_foreground: 'hsla(var(--color-primary-foreground) / <alpha-value>)',
  primary_foreground_vivid: 'hsla(var(--color-primary-foreground-vivid) / <alpha-value>)',
  primary_foreground_dull: 'hsla(var(--color-primary-foreground-dull) / <alpha-value>)',
  primary_vivid: 'hsla(var(--color-primary-vivid) / <alpha-value>)',
  primary_dull: 'hsla(var(--color-primary-dull) / <alpha-value>)',
  secondary: 'hsla(var(--color-secondary) / <alpha-value>)',
  secondary_foreground: 'hsla(var(--color-secondary-foreground) / <alpha-value>)',
  secondary_foreground_vivid: 'hsla(var(--color-secondary-foreground-vivid) / <alpha-value>)',
  secondary_foreground_dull: 'hsla(var(--color-secondary-foreground-dull) / <alpha-value>)',
  secondary_vivid: 'hsla(var(--color-secondary-vivid) / <alpha-value>)',
  secondary_dull: 'hsla(var(--color-secondary-dull) / <alpha-value>)',
  danger: 'hsla(var(--color-danger) / <alpha-value>)',
  danger_vivid: 'hsla(var(--color-danger-vivid) / <alpha-value>)',
  danger_dull: 'hsla(var(--color-danger-dull) / <alpha-value>)',
  danger_foreground: 'hsla(var(--color-danger-foreground) / <alpha-value>)',
  danger_foreground_vivid: 'hsla(var(--color-danger-foreground-vivid) / <alpha-value>)',
  danger_foreground_dull: 'hsla(var(--color-danger-foreground-dull) / <alpha-value>)',
  safe: 'hsla(var(--color-safe) / <alpha-value>)',
  safe_vivid: 'hsla(var(--color-safe-vivid) / <alpha-value>)',
  safe_dull: 'hsla(var(--color-safe-dull) / <alpha-value>)',
  safe_foreground: 'hsla(var(--color-safe-foreground) / <alpha-value>)',
  safe_foreground_vivid: 'hsla(var(--color-safe-foreground-vivid) / <alpha-value>)',
  safe_foreground_dull: 'hsla(var(--color-safe-foreground-dull) / <alpha-value>)',
  gold: 'hsl(41,100%,50%)',
  mark: 'hsla(41,100%,50%,0.63)',
};
module.exports = color;
