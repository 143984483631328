import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';

export function list_merge_deep<T extends Record<string, any> = any>(
  list: T[],
  patch: T[],
  key: keyof T = 'id' as any,
): T[] {
  const l = keyBy(list, key);
  const p = keyBy(patch, key);
  return values(merge({}, l, p));
}
