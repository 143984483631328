import React, { memo, SVGAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Icon_load from '../icon_load/icon_load';

export interface I_Icon_loading extends SVGAttributes<any> {}

function Icon_loading({ className, ...rest }: I_Icon_loading) {
  return <Icon_load className={twMerge('animate-spin', className)} {...rest} />;
}

export default memo(Icon_loading) as typeof Icon_loading;
