import { T_context_nf } from '@/context/context_nf';
import { Context_ws } from '@/context/context_ws';
import { N_event_ws } from '@/declaration/type/n_event_ws';
import { T_event } from '@/declaration/type/t_event';
import { list_merge_deep } from '@/utility/array/list_merge_deep';
import merge from 'lodash/merge';
import { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

export interface O_init_notification {
  context: {
    nf: T_context_nf;
  };
}

export function useInitNotification(): O_init_notification {
  const [nf, set_nf] = useState<T_context_nf>(new T_context_nf());
  const { main } = useContext(Context_ws);

  useEffect(() => {
    const sub: Record<string, Subscription | undefined> = {};
    void setup_nf();

    async function setup_nf(): Promise<void> {
      const events = [N_event_ws.model__nf_create, N_event_ws.model__nf_update, N_event_ws.model__nf_count_unread];
      await main?.subscribe({ events });
      sub.ws_nf = main?.radio.subscribe((r: T_event<N_event_ws>): void => {
        switch (r.type) {
          case N_event_ws.model__nf_count_unread:
            set_nf(merge({}, nf, { unread: r.data }));
            break;
          case N_event_ws.model__nf_create:
          case N_event_ws.model__nf_update:
            const list = r.data.list;
            if (list?.length) {
              set_nf((s) => {
                return { ...s, list: list_merge_deep(s.list || [], list) };
              });
            }
            break;
        }
      });
    }

    return () => {
      for (const key in sub) {
        sub[key]?.unsubscribe();
      }
    };
  }, [main, nf]);

  return {
    context: {
      nf,
    },
  };
}
