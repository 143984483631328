/**
 * Local storage keys
 */
export enum N_lkey {
  setting = 'setting', // current user settings
  version = 'version', // client version
  theme = 'theme', // dark mode
  vcode_countdown = 'vcode_countdown', // client version
  auth = 'auth',
  sign_redirect = 'sign_redirect', // login redirect
  id_inviter = 'id_inviter', // inviter id
  feedback = 'feedback', // feedback
}
