import { T_token_bag } from '../declaration/api/type/t_token_bag';
import { N_lkey } from '../declaration/type/n_lkey';
import lget from '../utility/storage/lget';
import lset from '../utility/storage/lset';

export function get_tokens(): T_token_bag[] {
  return lget([N_lkey.auth]) || [];
}

/**
 * (login)
 */
export function pin_token(td: T_token_bag): void {
  let list = get_tokens();
  if (!Array.isArray(list)) {
    list = [];
  }
  if (td.signer) {
    list = list.filter((it) => it.signer?.id !== td.signer?.id);
  } else {
    list = list.filter((it) => it.authorization !== td.authorization);
  }
  list.unshift(td);
  lset([N_lkey.auth], list);
  document.cookie = `authorization=${td.authorization}; path=/;`;
}

/**
 * Logout
 */
export function remove_token(td?: T_token_bag): void {
  const t = td || get_tokens()[0];

  let list = get_tokens();
  if (!list) {
    return;
  }
  lset(
    [N_lkey.auth],
    list.filter((it) => it.authorization !== t?.authorization),
  );
  document.cookie = `authorization=; path=/;`;
}
