import React, { ButtonHTMLAttributes, FC, forwardRef, memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Btn extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  color?: 'primary' | 'secondary' | 'light' | 'danger' | 'risky' | 'safe';
  variant?: 'solid' | 'text';
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  full_width?: boolean;
  ref?: any;
}

const Btn: FC<I_Btn> = memo<I_Btn>(
  forwardRef(({ primary, type, variant, children, className, full_width, size, color, ...rest }: I_Btn, ref: any) => {
    const _primary = primary === true || (primary !== false && type === 'submit');
    if (_primary) {
      color = 'primary';
    }

    const klass = useMemo(
      () => ` btn_${variant ?? 'solid'}_${color ?? 'secondary'} btn_${size ?? 'base'} ${full_width ? 'w-full' : ''}`,
      [color, full_width, size, variant],
    );

    return (
      <button
        ref={ref}
        {...rest}
        type={type ?? 'button'}
        className={twMerge('btn', klass, className)}
        data-testid="Btn"
      >
        {children}
      </button>
    );
  }) as any,
);

export default Btn;
