import { Account } from '../../declaration/rds/model';
import { N_role } from '../../declaration/rds/model/type/n_role';

export function signer_is(signer?: Account | null, role?: N_role): boolean {
  if (!signer || !role) {
    return false;
  }
  const user = signer?.r_user;
  const list = user?.roles_complete;
  return !!(list?.includes(N_role.super) || list?.includes(role));
}
