import { useRouterPath } from '@/hook/router/useRouterPath';
import { is_empty_object } from '@/utility/object/is_empty_object';
import from_qs from '@/utility/path/qs/from_qs';
import to_qs from '@/utility/path/qs/to_qs';
import mg from 'lodash/merge';
import omit from 'lodash/omit';
import { useCallback } from 'react';

function path_normalize(path?: string): string {
  let p = path?.trim();
  if (!p) {
    p = '/';
  }
  return p;
}

export default function useQueryModify(): {
  merge: (q: Record<string, string>) => string;
  subtract: (keys: string[]) => string;
} {
  const [path, qs] = useRouterPath();

  const merge = useCallback(
    (q: Record<string, string>): string => path_normalize(path) + '?' + to_qs(mg({}, from_qs(qs), q)),
    [path, qs],
  );

  const subtract = useCallback(
    (keys: string[]): string => {
      const q = from_qs(qs?.toString() ?? '');
      const map = omit(q, keys);
      const p = path_normalize(path);
      const is_empty_map = is_empty_object(map);
      if (p === '/' && is_empty_map) {
        return '';
      }
      if (is_empty_map) return p.split('?')[0];
      return p + '?' + to_qs(map);
    },
    [path, qs],
  );

  return { merge, subtract };
}
