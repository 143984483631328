import { Legal_entity } from '@/declaration/rds/model';
import { SnackbarOrigin, VariantType } from 'notistack';
import { Subject } from 'rxjs';
import { T_token_bag } from '../declaration/api/type/t_token_bag';
import { N_event_money } from '../declaration/type/n_event_money';

export interface T_popup {
  title?: string;
  key?: string;
  detail?: string;
  type?: VariantType;
  duration?: number;
  position?: SnackbarOrigin;
  data?: any;
}

export interface T_event_bag<Name extends string = string, Data = any> {
  name: Name;
  data?: Data;
}

/**
 * Global event center
 */
export const radio = {
  popup: new Subject<T_popup | string>(),
  error: new Subject<any>(),
  authorization: new Subject<T_token_bag | null>(),
  su_unique_change: new Subject<string | null>(),
  money: new Subject<T_event_bag<N_event_money.balance_change> | T_event_bag<N_event_money.balance_lock>>(),
  legal_entity_verify_success: new Subject<Legal_entity>(),
};
