import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { I_app_get_state } from '../declaration/api/type/i_app_get_state';
import { T_app_state } from '../declaration/api/type/t_app_state';
import { N_lkey } from '../declaration/type/n_lkey';
import lget from '../utility/storage/lget';
import lset from '../utility/storage/lset';
import { Api } from './$api';
import { service } from './service';

const name = 'app';

export class App extends Api {
  name = name;
  state?: T_app_state;

  async load_state<T = T_app_state>(opt?: I_app_get_state, refresh = false): Promise<T> {
    const paths = opt?.paths;
    let r: any;
    if (paths) {
      r = pick(this.state, ...paths);
    } else {
      r = this.state;
    }

    if (!Object.keys(r).length || refresh) {
      r = this.state = merge({}, this.state, await this.get_state(opt));
    }

    return paths ? pick(r, ...paths) : (r as any);
  }

  async get_state<T = T_app_state>(opt?: I_app_get_state): Promise<T> {
    return this.call(`${name}/get_state`, opt);
  }

  async get_version(refresh = false): Promise<string> {
    const path = ['client', 'web', 'version'];
    const r = await this.load_state({ paths: [path] }, refresh);
    return r?.client?.web?.version;
  }

  async update(refresh = false): Promise<void> {
    const version = await this.get_version(refresh);
    if (version && version !== lget([N_lkey.version, 'latest'])) {
      lset([N_lkey.version, 'latest'], version);
      window.location.reload();
    }
  }
}

export const $app = service<App>(App, name);
