import set from 'lodash/set';
import { T_lpath } from '../../declaration/type/t_lpath';
import lget from './lget';

/**
 * Set localStorage JSON
 */
export default function lset([key, ...path]: T_lpath, value: any): void {
  if (typeof window === 'undefined') {
    return;
  }

  if (value === undefined) {
    value = null;
  }

  let v = value;

  if (path?.length) {
    v = lget([key]) || {};
    set(v, path, value);
  }

  localStorage.setItem(key, JSON.stringify(v));
}
