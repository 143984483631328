import { useRouterPath } from '@/hook/router/useRouterPath';
import merge from 'lodash/merge';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import to_qs from '../../utility/path/qs/to_qs';
import useQueryModify from './useQueryModify';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

/**
 * Default transition options
 */
const def: TransitionOptions = {
  shallow: true,
  scroll: false,
};

export default function useQueryTo(): {
  /**
   * Query string patch
   */
  patch(q: Record<string, string>, opt?: TransitionOptions): void;
  /**
   * Query string subtract
   */
  subtract(keys: string[], opt?: TransitionOptions): void;
  /**
   * Query string overwrite
   */
  query(q: Record<string, string>, path?: string, opt?: TransitionOptions): void;
} {
  const { push } = useRouter();
  const { merge: mer, subtract: sub } = useQueryModify();
  const [pathname] = useRouterPath();
  const _patch = useCallback(
    (q: Record<string, string>, opt?: TransitionOptions): void => {
      const v = mer(q)?.trim();
      void push(v, undefined, merge({}, def, opt));
    },
    [mer, push],
  );

  const _subtract = useCallback(
    (keys: string[], opt?: TransitionOptions): void => {
      const v = sub(keys)?.trim();
      void push(v, undefined, merge({}, def, opt));
    },
    [push, sub],
  );

  const _query = useCallback(
    (q: Record<string, string>, path?: string, opt?: TransitionOptions): void => {
      path = path || pathname;
      path += '?' + to_qs(q);
      void push(path as string, undefined, merge({}, def, opt));
    },
    [pathname, push],
  );

  return { patch: _patch, subtract: _subtract, query: _query };
}
