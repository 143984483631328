import { O_init_auth, useInitAuth } from '@/hook/app/useInitAuth';
import { O_init_notification, useInitNotification } from '@/hook/app/useInitNotification';
import { O_init_root, useInitRoot } from '@/hook/app/useInitRoot';

export interface O_init {
  root: O_init_root;
  auth: O_init_auth;
  nf: O_init_notification;
}

export function useInit(): O_init {
  const root = useInitRoot();
  const auth = useInitAuth();
  const nf = useInitNotification();
  return { root, auth, nf };
}
