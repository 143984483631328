import merge from 'lodash/merge';
import { useCallback, useState } from 'react';
import { $api } from '../../service/$api';

export interface I_use_api {
  interceptor?: () => boolean;
}

export interface O_use_api<R> {
  result: R;
  pending: boolean;
  call: (patch?: any) => Promise<R>;
  set_body: (body: any) => void;
}

export function useApi<R = any>(url: string, opt?: I_use_api): O_use_api<R> {
  opt = opt || {};
  const { interceptor } = opt;
  const [result, set_result] = useState<any>();
  const [pending, set_pending] = useState<boolean>(false);
  const [input, set_body] = useState<any>();
  const call = useCallback(
    async (args?: any) => {
      if (interceptor && !interceptor()) {
        return;
      }
      let opt = input;
      if (args) {
        opt = merge({}, input, args);
      }
      set_pending(true);
      const r = await $api.call(url, opt).finally(() => set_pending(false));
      set_result(r);
      return r;
    },
    [input, interceptor, url],
  );

  return { result, pending, call, set_body };
}
