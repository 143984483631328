import get from 'lodash/get';
import { T_lpath } from '../../declaration/type/t_lpath';

/**
 * Get localStorage JSON
 */
export default function lget<T = any>([key, ...path]: T_lpath): T | undefined {
  if (typeof window === 'undefined') {
    return;
  }
  const json = localStorage.getItem(key);
  if (!json) {
    return;
  }
  const data = JSON.parse(json);
  if (!path?.length) {
    return data;
  } else {
    return get(data, path);
  }
}
