import Root from '@/component/structure/root/root';
import { T_props_with_layout } from '@/declaration/type/t_props_with_layout';
import '@/style/globals.scss';
import '@/style/overwrite/mui.scss';
import { appWithTranslation } from 'next-i18next';

// // For non-routing strategy
// void i18n_init();

const App = ({ Component, pageProps }: T_props_with_layout) => {
  const get_layout = Component.get_layout ?? ((page) => page);
  return <Root>{get_layout(<Component {...pageProps} />)}</Root>;
};

export default appWithTranslation(App as any);
