export enum N_role {
  debug = 'debug',
  tourist = 'tourist',
  super = 'super', // Super user
  regular = 'regular',
  internal = 'internal', // internal user
  employee = 'employee',
  shareholder = 'shareholder',
  account_manager = 'account_manager',
  resource_manager = 'resource_manager',
  finance_manger = 'finance_manger',
  product_manger = 'product_manger',
  file_manager = 'file_manager',
  feedback_manager = 'feedback_manager',
  wiki_manager = 'wiki_manager',
  wiki_writer = 'wiki_writer',
  re_manager = 're_manager',
  brand_manager = 'brand_manager',
  reviewer_manager = 'reviewer_manager',
}
