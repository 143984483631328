import { T_token_bag } from '@/declaration/api/type/t_token_bag';
import { N_role } from '@/declaration/rds/model/type/n_role';
import { T_signer } from '@/declaration/type/t_signer';
import { createContext } from 'react';

export interface T_context_auth {
  signer: T_signer | null;
  auths?: T_token_bag[];
  reload_signer: () => Promise<void>;
  setting_set: (path: string, value: any) => Promise<void>;
  load_count?: number; // signer loaded at least once

  /**
   * Is role
   */
  is_role(role: N_role): boolean;
}

export const Context_auth = createContext<T_context_auth>({
  signer: null,
} as T_context_auth);
