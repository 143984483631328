import { url_login } from '@/constant/url_login';
import { T_context_theme } from '@/context/context_theme';
import { context_ws_value_create, T_context_ws } from '@/context/context_ws';
import { N_lkey } from '@/declaration/type/n_lkey';
import { N_theme } from '@/declaration/type/n_theme';
import { $app } from '@/service/$app';
import { $theme } from '@/service/$theme';
import { logout } from '@/state/auth';
import { radio, T_popup } from '@/state/radio';
import { theme_dark } from '@/style/theme/mui/dark';
import { theme_light } from '@/style/theme/mui/light';
import lset from '@/utility/storage/lset';
import { Theme } from '@mui/material/styles/createTheme';
import { createRef, useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';
import { useImmer } from 'use-immer';

export interface O_init_root {
  context: {
    ws: T_context_ws;
    theme: T_context_theme;
  };
  theme: Theme;
  notistack: {
    ref: any;
    dismiss: (key: any) => () => void;
  };
}

function create_theme(theme: N_theme | null): Theme {
  const mode = !theme ? $theme.os_theme : theme;
  return mode === N_theme.light ? theme_light : theme_dark;
}

export function useInitRoot(): O_init_root {
  const context_value_ws = useMemo(() => context_ws_value_create(), []);
  const [context_value_theme, set_context_value_theme] = useImmer<T_context_theme>({
    current: null,
    service: $theme,
  });
  const notistack_ref: any = createRef();
  const [theme, set_theme] = useState<any>(create_theme(N_theme.light));

  const notistack_dismiss = (key: any) => () => {
    notistack_ref.current.closeSnackbar(key);
  };

  useEffect(() => {
    void $app.update(true);
    window.addEventListener('focus', () => $app.update(true));
  }, []);

  useEffect(() => {
    let dead = false;
    const sub: Record<string, Subscription> = {};
    // const l = $language;

    setup_error();
    setup_theme();
    void setup_i18n();

    function setup_error(): void {
      sub.error = radio.error.subscribe((e) => {
        radio.popup.next({
          title: e.message,
          key: e.eid || e.name.toLowerCase(),
          data: e.data,
          detail: e.solution,
          type: 'error',
        } as T_popup);
        switch (e.eid) {
          case 'e8': // invalid credential
            logout();
            lset([N_lkey.sign_redirect], window.location.pathname);
            window.location.href = url_login;
            break;
        }
      });
    }

    async function setup_i18n(): Promise<void> {
      // Guess and set lang after subscription
      // await l.load_langs();
      // l.set_lang_smart();
    }

    function setup_theme(): void {
      sub.theme = $theme.current$.subscribe((v) => {
        if (dead) return;
        theme_load(v);
      });
      $theme.init();
      $theme.detect_os_theme_change();
      theme_load($theme.current);
    }

    function theme_load(v: N_theme | null): void {
      set_theme(create_theme(v));
      set_context_value_theme((s) => {
        s.current = v;
      });
    }

    return () => {
      dead = true;
      for (const key in sub) {
        sub[key]?.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    context: {
      ws: context_value_ws,
      theme: context_value_theme,
    },
    theme,
    notistack: {
      ref: notistack_ref,
      dismiss: notistack_dismiss,
    },
  };
}
