import { Subject } from 'rxjs';
import { N_lkey } from '../declaration/type/n_lkey';
import { N_theme } from '../declaration/type/n_theme';
import { is_os_dark_mode } from '../utility/browser/is_os_dark_mode';
import { theme_media_get } from '../utility/browser/theme_media_get';
import lget from '../utility/storage/lget';
import lset from '../utility/storage/lset';
import { Api } from './$api';
import { service } from './service';

const name = 'theme';

export class Theme extends Api {
  name = name;
  os_theme!: N_theme.dark | N_theme.light;
  current$ = new Subject<N_theme | null>();
  current: N_theme | null = null;
  media!: MediaQueryList;
  meta: HTMLMetaElement | null = null;

  init() {
    this.current$.subscribe((value) => this.set(value));
    this.reload();
  }

  eval(): N_theme {
    return this.current || this.os_theme;
  }

  set(value: N_theme | null) {
    this.current = value;
    lset([N_lkey.theme], value ?? null);
    switch (value) {
      case null:
      case undefined:
        this.class_set(this.os_theme === N_theme.dark);
        break;
      case N_theme.light:
        this.class_set(false);
        break;
      case N_theme.dark:
        this.class_set(true);
        break;
    }
    const color = getComputedStyle(document.body).getPropertyValue('background-color');
    if (color) {
      this.meta?.setAttribute('content', color);
    }
  }

  clear() {
    this.current$.next(null);
  }

  detect_os_theme_change() {
    const { media } = this;
    media.addEventListener('change', () => this.reload());
  }

  reload() {
    this.meta = document.querySelector('meta[name="theme-color"]');
    this.media = theme_media_get();
    this.os_theme = is_os_dark_mode() ? N_theme.dark : N_theme.light;
    const value = lget([N_lkey.theme]) as N_theme | null;
    this.current$.next(value);
  }

  private class_set(to_dark: boolean) {
    const root_class = document.documentElement.classList;
    if (to_dark) {
      root_class.add(N_theme.dark);
      root_class.remove(N_theme.light);
    } else {
      root_class.add(N_theme.light);
      root_class.remove(N_theme.dark);
    }
  }
}

export const $theme = service<Theme>(Theme, name);
