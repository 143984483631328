import Btn, { I_Btn } from '@/component/button/btn/btn';
import React, { FC, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Btn_action extends I_Btn {}

const Btn_action: FC<I_Btn_action> = memo<I_Btn_action>(
  forwardRef((props: I_Btn_action, ref: any) => {
    const { children, className } = props;

    return (
      <Btn data-testid="Btn_action" {...props} ref={ref} className={twMerge('btn_action', className)}>
        {children}
      </Btn>
    );
  }) as any,
);

export default Btn_action;
